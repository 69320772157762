.a {
    cursor: pointer;
    color: blue;
}

.a:hover {
    text-decoration: underline;
}

.files-list {
    padding: 0;
    margin: 0;
    list-style: none;
}